import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import AuthModal from './components/AuthModal';
import Cabinet from './components/Cabinet';
import Terms from './components/Terms';
import TicketList from './components/TicketList';

// Импортируем изображения
import ticket200 from './images/ticket-200.jpg';
import ticket500 from './images/ticket-500.jpg';
import ticket1000 from './images/ticket-1000.jpg';

function App() {
  const [isAuth, setIsAuth] = useState(false);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [showCabinet, setShowCabinet] = useState(false);
  const [balance, setBalance] = useState(1500);

  return (
    <div className="app">
      <Header 
        isAuth={isAuth} 
        onAuthClick={() => setShowAuthModal(true)}
        onCabinetClick={() => setShowCabinet(true)}
        balance={balance}
      />
      
      <main className="main-content">
        <Routes>
          <Route path="/" element={
            showCabinet ? (
              <Cabinet 
                balance={balance}
                onBalanceChange={setBalance}
              />
            ) : (
              <>
                <div className="hero-section">
                  <h1>Искусство, рожденное ИИ: уникальные картины для вашего вдохновения.</h1>
                  <h2> Выбирайте стиль, цвета и сюжет — алгоритмы создадут эксклюзивное полотно в единственном экземпляре. </h2>
                </div>
                <TicketList />
                <h2> При покупке каждая картина создается в единственном экземпляре — вы становитесь владельцем эксклюзивного произведения без повторов и копий.
                Где технологии становятся искусством, а ваш интерьер — галереей будущего. </h2>
              </>
            )
          } />
          <Route path="/terms" element={<Terms />} />
        </Routes>
      </main>

      <Footer />

      {showAuthModal && (
        <AuthModal 
          onClose={() => setShowAuthModal(false)}
          onSuccess={(userData) => {
            setIsAuth(true);
            setShowAuthModal(false);
          }}
        />
      )}
    </div>
  );
}

export default App;